<template>
    <div class="app-content app-container">
        <!-- 搜索 -->
        <div style="margin-bottom: 20px">
            <el-form
                :inline="true"
                :model="formInline"
                class="demo-form-inline"
                ref="formInline"
                label-width="100px"
                size="medium"
                @submit.native.prevent
            >
                <el-form-item label="信用代码" prop="uscc">
                    <el-input
                        style="width: 300px"
                        v-model="formInline.uscc"
                        placeholder="请输入"
                        clearable
                        @keyup.enter.native="onSubmit"
                    ></el-input>
                </el-form-item>
                <el-form-item label="名称" prop="name">
                    <el-input
                        style="width: 300px"
                        v-model="formInline.name"
                        placeholder="请输入"
                        clearable
                        @keyup.enter.native="onSubmit"
                    ></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="onSubmit">搜索</el-button>
                    <el-button @click="resetForm('formInline')">重置</el-button>
                </el-form-item>
            </el-form>
            <div>
                <el-button type="danger" plain @click="allDeleteClick"
                    >删除</el-button
                >
            </div>
        </div>
        <!-- 表格 -->
        <div>
            <el-table
                ref="multipleTable"
                :data="tableData"
                tooltip-effect="dark"
                style="width: 100%"
                @selection-change="handleSelectionChange"
            >
                <el-table-column type="selection" width="55"></el-table-column>

                <el-table-column prop="id" label="#"> </el-table-column>
                <el-table-column
                    prop="uscc"
                    label="信用代码"
                     
                >
                </el-table-column>
                <el-table-column prop="name" label="名称"  >
                </el-table-column>
                <el-table-column prop="job" label="职务"  >
                </el-table-column>
                <el-table-column
                    prop="phoneNumber"
                    label="手机"
                     
                >
                </el-table-column>
                <el-table-column
                    prop="status"
                    label="核实状态"
                     
                >
                    <template slot-scope="scope">{{
                        scope.row.status | effective
                    }}</template>
                </el-table-column>
                <el-table-column prop="time" label="时间"  >
                    <template slot-scope="scope">{{
                        scope.row.time | dayjs
                    }}</template>
                </el-table-column>
                <el-table-column label="操作" fixed="right" width="50">
                    <template slot-scope="scope">
                        <el-button type="text" @click="editClick(scope.row)"
                            >编辑</el-button
                        >
                    </template>
                </el-table-column>
            </el-table>
            <!-- 分页 -->
            <div>
                <Pagination
                    v-bind:child-msg="pageparm"
                    @callFather="callFather"
                ></Pagination>
            </div>
        </div>
        <!-- 弹窗 -->
        <el-dialog title="信息" :visible.sync="dialogVisible" width="30%">
            <div>
                <el-form label-width="80px" ref="ruleForm"  :model="formLabelAlign" :rules="rules">
                    <el-form-item label="企业ID" prop="cid">
                        <el-input v-model="formLabelAlign.cid"></el-input>
                    </el-form-item>
                    <el-form-item label="信用代码" prop="uscc">
                        <el-input v-model="formLabelAlign.uscc"></el-input>
                    </el-form-item>
                    <el-form-item label="联系人" prop="name">
                        <el-input v-model="formLabelAlign.name"></el-input>
                    </el-form-item>
                    <el-form-item label="职务" prop="job">
                        <el-input v-model="formLabelAlign.job"></el-input>
                    </el-form-item>
                    <el-form-item label="手机" prop="phoneNumber">
                        <el-input
                            v-model="formLabelAlign.phoneNumber"
                        ></el-input>
                    </el-form-item>
                    <el-form-item label="电话1" prop="tel1">
                        <el-input v-model="formLabelAlign.tel1"></el-input>
                    </el-form-item>
                    <el-form-item label="电话2" prop="tel2">
                        <el-input v-model="formLabelAlign.tel2"></el-input>
                    </el-form-item>
                    <el-form-item label="状态" prop="status">
                        <el-select
                            style="width: 100%"
                            v-model="formLabelAlign.status"
                            placeholder="请选择"
                        >
                            <el-option
                                v-for="item in typeList"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value"
                            >
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-form>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="editSubmitClick('ruleForm')"
                    >确 定</el-button
                >
            </span>
        </el-dialog>
    </div>
</template>
<script>
import Pagination from '@/components/Pagination'
import {
    getCompanyUserListApi,
    getCompanyUserUpdateApi,
    getCompanyUserDeleteApi,
} from '@/api/company'
export default {
    // 注册组件
    components: {
        Pagination,
    },
    data() {
        return {
            dialogVisible: false,
            typeList: [
                {
                    label: '有效',
                    value: 0,
                },
                {
                    label: '无效',
                    value: -1,
                },
            ],
            formInline: {},
            tableData: [],
            multipleSelection: [],
            pageparm: {
                currentPage: 1,
                pageSize: 10,
                total: 20,
            },
            formLabelAlign: {},
             rules: {
                uscc: [
                    { required: true, message: '请输入信用代码', trigger: 'blur' },
                ],
                name: [
                    { required: true, message: '请输入联系人', trigger: 'blur' }
                ],
                phoneNumber: [
                    { required: true, message: '请输入手机号', trigger: 'blur' },
            				{ pattern: /^1\d{10}$/, trigger: "blur", message: "手机号格式错误"  }
                ],
                status: [
                    { required: true, message: '请选择状态', trigger: 'blur' }
                ],
                
            },
        }
    },
    created() {
        if (sessionStorage.getItem('formInline')) {
            this.formInline = JSON.parse(sessionStorage.getItem('formInline'))
        }
        this.getCompanyUserListFun()
    },
    methods: {
        async getCompanyUserListFun(currentPage, pageSize) {
            var start = currentPage ? currentPage * pageSize - pageSize : 0
            var params = {
                start: start,
                length: pageSize ? pageSize : 10,
                draw: 1,
                orderStr:'time desc'
            }
            var p = Object.assign(params, this.formInline)
            var res = await getCompanyUserListApi(p)
            console.log('企业联系人', res)
            this.pageparm.total = res.recordsTotal
            this.tableData = res.data
        },
        onSubmit() {
            sessionStorage.setItem(
                'formInline',
                JSON.stringify(this.formInline)
            )
            console.log('onSubmit', this.formInline)
            this.getCompanyUserListFun()
        },
        resetForm(formInline) {
            sessionStorage.removeItem('formInline')
            this.formInline = {
                name: '',
            }
            this.$refs[formInline].resetFields()
            this.getCompanyUserListFun()
        },
        handleSelectionChange(val) {
            this.multipleSelection = val
        },
        // 分页插件事件
        callFather(parm) {
            this.getCompanyUserListFun(parm.currentPage, parm.pageSize)
        },
        // 删除
        allDeleteClick() {
            var ids = this.multipleSelection.map((item) => item.id)
            this.$alert(
                '这确定要删除共' +
                    this.multipleSelection.length +
                    '条数据吗？删除后将无法恢复',
                '删除提示',
                {
                    confirmButtonText: '确定',
                    callback: async (action) => {
                        if (action === 'confirm') {
                            var params = {
                                ids: ids,
                                menuType: 'delete',
                            }
                            var res = await getCompanyUserDeleteApi(params)
                            console.log('删除结果', res)
                            if (res.code === 200) {
                                this.$message({
                                    type: 'success',
                                    message: '删除成功',
                                })
                                this.getCompanyUserListFun()
                            } else {
                                this.$message({
                                    type: 'error',
                                    message: '删除失败：' + res.message,
                                })
                            }
                        }
                    },
                }
            )
        },
        // 编辑模板
        editClick(row) {
            console.log('row', row)
            this.formLabelAlign = row
            this.dialogVisible = true
        },
        // 编辑提交
        editSubmitClick(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.editSubmit()
                    
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
            
        },
        // 提交数据
        async editSubmit() {
            delete this.formLabelAlign.time
            var res = await getCompanyUserUpdateApi(this.formLabelAlign)
            if (res.code === 200) {
                this.$message({
                    message: '更新成功',
                    type: 'success',
                })
                this.dialogVisible = false
                this.getCompanyUserListFun()
            } else {
                this.$message({
                    message: '更新失败',
                    type: 'error',
                })
            }
        },
    },
}
</script>
<style scoped>
.app-content {
    background-color: #fff;
    padding: 20px;
}
</style>
